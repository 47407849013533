import React from "react";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";
export const Hero = ({ title, writtenBy, timeStamp, image, logo }) => {
  return (
    <div className="common-hero-style hero-section position-relative">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img
              src={image}
              alt="Service Image"
              className="img-fluid img-blog"
            />
          </div>
          <div className="col-lg-6">
            <div className="hero-text-section position-relative z-index-1">
              <h1 className="hero-title">{title}</h1>
              {/* <p className="hero-p">
                Web designing in a powerful way of just not an only professions,
                however, in a passion for our Company. We have to a tendency to
                believe the idea that smart looking of any website in on
                visitors.Web designing in a powerful way of just not an only
                profession.
              </p> */}
              <div class="author d-flex align-items-center gap-2">
                <div class="author-image">
                  <img src={logo} alt="author image" />
                </div>
                <div class="author-details">
                  <h4 class="mb-0">
                    Written by <span>{writtenBy}</span>
                  </h4>
                  <p class="mb-0">{timeStamp}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
