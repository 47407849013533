import React from "react";
import "../../styles/scss/about.scss";
import { Hero } from "./AboutUsHero";
import { ModuleDevice } from "./ModuleDevice";
import { DirectorMessage } from "./DirectorMessage";
import { LatestBlogs } from "./latestblogs";
import { AboutContent } from "./About";
import { OurTeam } from "./ourTeam";

export const About = () => {
  return (
    <React.Fragment>
      <div className="about-page">
        <Hero />
        <AboutContent />
        <DirectorMessage />
        {/* <LatestBlogs /> */}
        {/* <OurTeam/> */}
      </div>
    </React.Fragment>
  );
};
