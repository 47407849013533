import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const textOptions = {
    Features: {
      list: [
        "Device Management: Remote monitoring, configuration, and management.",
        "Data Transmission: Supports seamless data transmission between devices and cloud platforms.",
        "Security: Equipped with secure communication, authentication, and access control mechanisms.",
        "Flexible Deployment: Suitable for local IoT networks, cloud-based, or hybrid solutions.",
        "Wi-Fi and GSM Support: Dual-mode 2.4GHz Wi-Fi and quad-band GSM/GPRS connectivity.",
        "RS485 Device Gateway: Acts as an IoT gateway for RS485-enabled devices.",
      ],
    },
    Industries:
      "The VAE RTU IoT is perfectly suited for industries such as manufacturing, energy, utilities, and infrastructure, where real-time monitoring, operational efficiency, and secure wireless communication are essential.",
  };

  const [displayText, setDisplayText] = useState("");
  const [featureList, setFeatureList] = useState([]);

  const changeText = (text) => {
    if (text === "Features") {
      setDisplayText(textOptions[text].description);
      setFeatureList(textOptions[text].list);
    } else {
      setDisplayText(textOptions[text]);
      setFeatureList([]);
    }
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"].description);
    setFeatureList(textOptions["Features"].list);
  }, []);

  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText ===
                (option === "Features"
                  ? textOptions[option].description
                  : textOptions[option])
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }}
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
          {featureList.length > 0 && (
            <ul className="fs-5">
              {featureList.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
