import React from "react";
import { FeaturesIndustries } from "./FeaturesIndustries";
import { TechnicalSpecs } from "./TechnicalSpecs";

export const FeaturesIndustriesTech = () => {
    return (
        <React.Fragment>
            <div className="container feature-tech-specs d-flex flex-md-row flex-column mt-3">
                <div className="row">
                   <FeaturesIndustries />
                    <TechnicalSpecs />
                </div>
            </div>
        </React.Fragment>
    );
}