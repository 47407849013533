import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const textOptions = {
    Features: {
      list: [
        "Standalone and Integrated Control: Functions as a manual on/off control panel while offering synchronization with the smart RTU for automated operations.",
        "VFD Technology: Utilizes Variable Frequency Drive technology for smooth, energy-efficient valve operation, minimizing wear and extending valve life.",
        "Customizable Design: Tailored configurations to accommodate varying operational requirements, supporting up to three valves.",
        "User-Friendly Interface: Intuitive controls and clear indicators enhance usability, making it easy for operators to manage valve operations.",
        "Robust Construction: Built with high-quality materials to withstand harsh industrial environments, ensuring longevity and reliability.",
        "Real-Time Monitoring: When integrated with the smart RTU, it provides real-time data and alerts for enhanced process management.",
        "Safety Features: Equipped with safety mechanisms to prevent accidental operation and ensure secure control.",
      ],
    },
    Industries:
      "The Actuator Valve Control Panel is designed to cater to a wide range of industries. In water and wastewater treatment, it efficiently manages flow control, ensuring optimal operations in both water distribution and wastewater management systems. In the oil and gas sector, it enables precise control over pipeline valves, enhancing safety and operational efficiency during extraction and processing. The panel is also invaluable in chemical processing, where it facilitates safe handling of corrosive substances, ensuring accuracy in valve operations. Additionally, in the food and beverage industry, it supports sanitary valve operations that comply with stringent industry standards, contributing to effective quality control. Furthermore, its application extends to HVAC systems, where it effectively manages airflow and temperature control in large commercial setups, improving both energy efficiency and comfort for occupants.",
  };

  const [displayText, setDisplayText] = useState("");
  const [featureList, setFeatureList] = useState([]);

  const changeText = (text) => {
    if (text === "Features") {
      setDisplayText(textOptions[text].description);
      setFeatureList(textOptions[text].list);
    } else {
      setDisplayText(textOptions[text]);
      setFeatureList([]);
    }
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"].description);
    setFeatureList(textOptions["Features"].list);
  }, []);

  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText ===
                (option === "Features"
                  ? textOptions[option].description
                  : textOptions[option])
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }}
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
          {featureList.length > 0 && (
            <ul className="fs-5">
              {featureList.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
