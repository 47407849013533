import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 texts">Actuator Valve Control Panel</h3>
        <p className="fs-5 texts mt-4">
          The Actuator Valve Control Panel by Visves Advance Engineering is a
          cutting-edge solution designed for efficient control of manual valves
          in various industrial applications. This standalone panel not only
          provides reliable manual on/off control but also seamlessly integrates
          with our advanced 3-Door Smart RTU Automated Control Panel. This
          unique capability allows the valve control panel to operate
          automatically according to user-defined programs, enhancing
          operational efficiency and flexibility.
        </p>
        <p className="fs-5 texts mt-4">
          Engineered with Variable Frequency Drive (VFD) technology, our
          actuator valve control panel ensures smooth and precise control over
          valve operations. It is customizable to meet specific requirements,
          enabling control of up to three valves simultaneously. Whether in
          standalone mode or synchronized with the smart RTU, this control panel
          empowers operators with the versatility they need to optimize
          processes in real time.
        </p>
      </div>
    </React.Fragment>
  );
};
