import React from "react";
import whychoose from "../../assets/images/carrer/why-choose.jpg";
import image2 from "../../assets/images/carrer/image2.png";
import image3 from "../../assets/images/carrer/image3.png";
export const WhyVisves = () => {
  return (
    <React.Fragment>
      <div className="why-choose py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img src={whychoose} className="w-100" />
            </div>
            <div className="col-lg-6">
              <p className="fs-2 underline underline-heading texts text-justify">Why Choose VISVES?</p>
              <p className="fs-5 text-body-secondary texts text-justify mb-0">
                At Visves, we are at the forefront of revolutionizing automation
                engineering. Our commitment to innovation, cutting-edge technology,
                and a collaborative work culture sets us apart. As we continue to push
                the boundaries of what's possible in automation, we are on the lookout
                for passionate individuals who are eager to shape the future of
                engineering
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
