import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import ApplyForm from "./ApplyForm";
import innovative from "../../assets/images/carrer/innovation-work.jpg";
import collaborate from "../../assets/images/carrer/collaborative-culture.png";
import growth from "../../assets/images/carrer/growth.png";
import project from "../../assets/images/carrer/project.png";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

export const Offer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentSection, setCurrentSection] = useState("All");
  const [selectedOpening, setSelectedOpening] = useState(null);
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  const openings = [
    {
      title: "Technician",
      Experience: "0-2 Years",
      location: "Ahmedabad",
      Qualification: "ITI (Electrical, Fitter, Wireman) / Professional Courses",
    },
    {
      title: "On-site Engineer",
      Experience: "1-3 Years",
      location: "Ahmedabad",
      Qualification:
        "DIPLOMA / B.E. (Electrical / Instrument & Control / Electronics and Communication)",
    },
    {
      title: "Assistant Administrative",
      Experience: "0-3 Years",
      location: "Ahmedabad",
      Qualification: "B.COM / BBA / M.COM / MBA",
    },
    {
      title: "Junior Engineer",
      Experience: "0-2 Years",
      location: "Ahmedabad",
      Qualification:
        "DIPLOMA / B.E. (Electrical / Instrument & Control / Electronics and Communication)",
    },
    {
      title: "Project Engineer",
      Experience: "1-3 Years",
      location: "Ahmedabad",
      Qualification:
        "M.E. / B.E. (Electrical / Instrument & Control / Electronics and Communication / Mechatronics)",
    },
  ];

  const toggleForm = (openingTitle) => {
    console.log("openingTitle", openingTitle);
    setShowForm(true);
    setSelectedOpening(openingTitle); // Set the selected opening title
  };

  return (
    <React.Fragment>
      <div className="offer">
        <div className="container">
          <div className="text-center my-3 py-3 border-bottom border-top">
            <h1 className="fs-2 underline underline-heading texts text-justify">
              What We Offer
            </h1>
          </div>
          <div className="row align-items-center border-bottom pb-3 mb-3">
            <div className="col-lg-6">
              <div className="offer-image">
                <img src={innovative} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bg-white p-4">
                <p className="fs-2 underline underline-heading texts text-justify">
                  1. Innovative Work Environment
                </p>
                <ul className="mt-3">
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Join a team of
                    industry leaders and experts who are dedicated to pushing
                    the limits of automation engineering.
                  </li>
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Work with
                    cutting-edge technologies and have the opportunity to
                    contribute to groundbreaking projects.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row align-items-center border-bottom pb-3 mb-3 flex-lg-row flex-column-reverse">
            <div className="col-lg-6">
              <div className="bg-white p-4">
                <p className="fs-2 underline underline-heading texts text-justify">
                  2. Professional Growth
                </p>
                <ul className="mt-3">
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Continuous learning
                    and development opportunities to help you stay ahead in your
                    field.
                  </li>
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Mentorship programs
                    and career advancement paths designed to foster your growth
                    within the organization.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="offer-image">
                <img src={growth} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row align-items-center border-bottom pb-3 mb-3">
            <div className="col-lg-6">
              <div className="offer-image">
                <img src={collaborate} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bg-white p-4">
                <p className="fs-2 underline underline-heading texts text-justify">
                  3. Collaborative Culture
                </p>
                <ul className="mt-3">
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Be part of a
                    dynamic and collaborative work culture where ideas are
                    valued, and teamwork is celebrated
                  </li>
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Engage in
                    cross-functional collaboration with experts from diverse
                    backgrounds, fostering a rich learning experience.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row align-items-center border-bottom pb-3 mb-3 flex-lg-row flex-column-reverse">
            <div className="col-lg-6">
              <div className="bg-white p-4">
                <p className="fs-2 underline underline-heading texts text-justify">
                  4. Impactful Projects
                </p>
                <ul className="mt-3">
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Contribute to
                    projects that make a real-world impact, shaping the future
                    of automation in industries ranging
                  </li>
                  <li className="fs-5 text-body-secondary texts text-justify mb-2">
                    <FontAwesomeIcon icon={faAnglesRight} /> Contribute to
                    projects that make a real-world impact, shaping the future
                    of automation in industries ranging.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="offer-image">
                <img src={project} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="bg-light p-5 mt-5">
            <div className="text-center mb-4 pb-4 border-bottom">
              <h2 className="fs-2 underline underline-heading texts">
                Current Openings
              </h2>
            </div>
            <div className="row mb-4 pb-4 border-bottom">
              <div className="col-12">
                {isMobile ? (
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {currentSection}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSectionChange("All")}
                        >
                          All
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSectionChange("Technician")}
                        >
                          Technician
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            handleSectionChange("On-site Engineer")
                          }
                        >
                          On-site Engineer
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            handleSectionChange("Assistant Administrative")
                          }
                        >
                          Assistant Administrative
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSectionChange("Junior Engineer")}
                        >
                          Junior Engineer
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            handleSectionChange("Project Engineer")
                          }
                        >
                          Project Engineer
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="d-flex button-tab justify-content-center">
                    <button
                      className={`btn ${
                        currentSection === "All" ? "active" : ""
                      }`}
                      onClick={() => handleSectionChange("All")}
                    >
                      All
                    </button>
                    <button
                      className={`btn ${
                        currentSection === "Technician" ? "active" : ""
                      }`}
                      onClick={() => handleSectionChange("Technician")}
                    >
                      Technician
                    </button>
                    <button
                      className={`btn ${
                        currentSection === "On-site Engineer" ? "active" : ""
                      }`}
                      onClick={() => handleSectionChange("On-site Engineer")}
                    >
                      On-site Engineer
                    </button>
                    <button
                      className={`btn ${
                        currentSection === "Assistant Administrative"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSectionChange("Assistant Administrative")
                      }
                    >
                      Assistant Administrative
                    </button>
                    <button
                      className={`btn ${
                        currentSection === "Junior Engineer" ? "active" : ""
                      }`}
                      onClick={() => handleSectionChange("Junior Engineer")}
                    >
                      Junior Engineer
                    </button>
                    <button
                      className={`btn ${
                        currentSection === "Project Engineer" ? "active" : ""
                      }`}
                      onClick={() => handleSectionChange("Project Engineer")}
                    >
                      Project Engineer
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {openings.map((opening, index) => (
                <React.Fragment key={index}>
                  {currentSection === "All" ||
                  currentSection === opening.title ? (
                    <>
                      <div className="col-lg-10 col-12 pb-3">
                        <p className="fs-5 mb-0">{opening.title}</p>
                        <p className="text-secondary mb-0">
                          Experience: {opening.Experience}
                        </p>
                        <p className="text-secondary mb-0">
                          Location: {opening.location}
                        </p>
                        <p className="text-secondary mb-0">
                          Qualification: {opening.Qualification}
                        </p>
                      </div>
                      <div className="col-lg-2 col-12 mb-4 mb-lg-0">
                        <button
                          className="brand-button w-100"
                          onClick={() => toggleForm(opening.title)}
                        >
                          Apply
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            style={{
                              verticalAlign: "middle",
                              paddingLeft: "10px",
                            }}
                          />
                        </button>
                        {showForm && (
                          <ApplyForm
                            selectedOpening={selectedOpening}
                            onClose={() => setShowForm(false)}
                            _isOpen={showForm}
                          />
                        )}
                      </div>
                      <hr className="col-lg-12 col-12" />
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
