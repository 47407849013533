import React from "react";
import img_ from "../../assets/images/aboutusImages/hero_page_wire.svg";
import imagex from "../../assets/images/aboutusImages/aboutUs-hero.jpg";
import heroImage from "../../assets/images/hero_alt.png";
import arrowRightIcon from "../../assets/images/img_.svg";

export const Hero = () => {
  return (
    <div className="common-hero-style hero-section position-relative py-5" style={{backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url(${imagex})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
    <div className="black-layer position-absolute top-0 start-0 end-0 bottom-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}></div>
    <div className="container">
      <div className="row d-flex position-relative">
        <div className="col-12 col-lg-8 py-5">
          <div className="hero-text-section position-relative z-index-1">
            <h3 className="text-about text-white">Visves Automation</h3>
            <h1 className="hero-title text-white">About Us</h1>
            <p className="hero-p text-white">
              We specialize in designing and engineering customized automation systems to optimize your business operations. With a focus on innovation and cost reduction, we're committed to giving your business the competitive edge it needs to thrive.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  );
};
