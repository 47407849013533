import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../../../../styles/scss/iot_module.scss";
import { Images } from "./Images";
import { ProductImageDesc } from "./ProductImageDesc";
import { FeaturesIndustriesTech } from "./FeaturesIndustriesTech";
import { RelatedProducts } from "./RelatedProducts";
import { BacktoproductPage } from "../BacktoproductPage";
export const VAEIOTRTU = () => {
  return (
    <React.Fragment>
      <div className=" product-page-landing">
        <BacktoproductPage />
        <ProductImageDesc />
        <FeaturesIndustriesTech />
        {/* <RelatedProducts /> */}
      </div>
    </React.Fragment>
  );
};
