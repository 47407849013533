import React from "react";
import UnderConstruction from "../../assets/images/under-construction.jpg";
import blogImg2 from "../../assets/images/products/IOTModule.jpg";
import blogImg3 from "../../assets/images/products/standardInterfacingModule.jpg";
import Pagination from "react-bootstrap/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

let active = 1;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}

export const UnderProcess = () => {
  return (
    <div className="under-process-wrap py-5">
      <div className="container">
        <div className="text-center">
          <img src={UnderConstruction} alt="Under Construction Image" />
          <h2 className="text-center mt-3 mb-4">
            This page is Under <span className="text-primary">maintenance</span>
          </h2>
          <Link to="/">
            <button className="brand-button">
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className="me-2 align-middle"
              />
              Back to Homepage
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
