import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TechnicalServices from "../../assets/images/serviceimg/technical-service.jpg";
import OperationalMaintenance from "../../assets/images/serviceimg/operation-maintenance.jpg";
import Consultancy from "../../assets/images/serviceimg/consultancy.jpg";
import IOTCloud from "../../assets/images/serviceimg/iot-cloud.jpg";
import Programming from "../../assets/images/serviceimg/programming.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const Service = () => {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const navigate = useNavigate();

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="services-page home-service-slider py-5">
      <div className="services-section">
        <div className="container">
          <div className="service-text-section">
            <div className="">
              <div className="text-center mb-4">
                <h5 className="text-main">SERVICES</h5>
                <h2 className="fs-2 underline underline-heading texts text-justify">
                  We Provide
                </h2>
              </div>
              <div className="next-prev">
                <div className="prev" onClick={previous}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ fontSize: "24px" }}
                  />
                </div>
                <div className="next" onClick={next}>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ fontSize: "24px" }}
                  />
                </div>
              </div>
              {/* Slick Slider starts here */}
              <Slider
                {...settings}
                ref={(slider) => {
                  sliderRef = slider;
                }}
              >
                <div>
                  <div className="service-box m-3">
                    <img src={Programming} alt="Service Image" />
                    <div className="service-box-cont">
                      <h3>Programming (PLC / HMI / Drive)</h3>
                      <p>
                        Our team specializes in the programming and
                        configuration of PLCs, HMIs, and Drives to automate and
                        optimize your industrial processes. We ensure seamless
                        integration between hardware and software, creating
                        efficient systems that maximize productivity and reduce
                        downtime.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="service-box m-3">
                    <img src={IOTCloud} alt="Service Image" />
                    <div className="service-box-cont">
                      <h3>IoT Cloud Management</h3>
                      <p>
                        Unlock the full potential of your systems with our IoT
                        cloud management services. We provide robust solutions
                        for connecting and monitoring devices in real time,
                        enabling you to access data remotely, improve
                        operational insights, and enhance decision-making across
                        your enterprise
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="service-box m-3">
                    <img src={Consultancy} alt="Service Image" />
                    <div className="service-box-cont">
                      <h3>
                        Consultancy (Project Planning, Hardware Selection,
                        System Integration)
                      </h3>
                      <p>
                        Our consultancy services guide you through every stage
                        of your project. From strategic planning and hardware
                        selection to system integration, we provide expert
                        advice that ensures you make informed decisions,
                        resulting in cost-effective and high-performance
                        solutions tailored to your specific requirements.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="service-box m-3">
                    <img src={TechnicalServices} alt="Service Image" />
                    <div className="service-box-cont">
                      <h3>
                        Technical Services and Support (Water Works Department,
                        Electrical Panels)
                      </h3>
                      <p>
                        We offer specialized technical services and support,
                        particularly for the Water Works Department and
                        electrical panel-related projects. Our expert
                        technicians provide comprehensive maintenance,
                        troubleshooting, and repairs to ensure smooth and
                        uninterrupted operations.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="service-box m-3">
                    <img src={OperationalMaintenance} alt="Service Image" />
                    <div className="service-box-cont">
                      <h3>
                        Operation and Maintenance of Systems (AMC and Operation
                        of Installed Systems)
                      </h3>
                      <p>
                        Our Annual Maintenance Contracts (AMC) ensure the
                        longevity and performance of your systems. We also offer
                        operation services for installed systems, handling all
                        aspects of maintenance and operation to keep your
                        infrastructure running efficiently and effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
              {/* Slick Slider ends here */}
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="brand-button"
                  onClick={() => navigate("/services")}
                >
                  Read More
                  <FontAwesomeIcon icon={faCircleArrowRight} className="ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
