import React from "react";
import { Hero } from "./Hero";
import "../../styles/scss/policy.scss";
import { PolicyContent } from "./PolicyContent";

export const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div className="policy-detail-page">
        <Hero />
        <PolicyContent />
      </div>
    </React.Fragment>
  );
};
