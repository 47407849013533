import React from "react";
import "../../styles/scss/solutionbyApplication.scss";
import { Hero1 } from "./hero";
import { Application } from "./application";
import { KeyApplication } from "./keyApplication";

export const SolutionsByApplication = () => {
  return (
    <React.Fragment>
      <div className="solution-page">
        <Hero1 />
        <Application/>
        <KeyApplication />
        </div>
    </React.Fragment>
  );
};
