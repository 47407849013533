import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

export const TechnicalSpecs = () => {
  const [isOpen, setIsOpen] = useState([false, false, false]); // State to track open/close status

  // Data for items including file paths
  const items = [
    {
      name: "SOP",
      files: {
        english: "/assets/documents/ActuatorValve/SOP_ENGLISH.pdf",
        hindi: "/assets/documents/ActuatorValve/SOP_HINDI.pdf",
      },
    },
  ];

  const toggleCollapse = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  return (
    <React.Fragment>
      <div className="col-lg-6 tech-specs d-flex flex-column">
        <div className="px-4 text-center">
          <h3 className="fs-2 underline underline-heading texts">
            Technical Specifications
          </h3>
          <div className="d-flex flex-column pt-3">
            {items.map((item, index) => (
              <div
                key={index}
                className={`mt-2 bg-dark text-white border rounded`}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ fontSize: "18px" }}
                >
                  <span style={{ padding: "15px" }}>{item.name}</span>
                  <FontAwesomeIcon
                    icon={isOpen[index] ? faAngleUp : faAngleDown}
                    style={{ cursor: "pointer", padding: "15px" }}
                    onClick={() => toggleCollapse(index)}
                  />
                </div>
                {isOpen[index] && (
                  <div className="d-block d-md-flex justify-content-between align-items-center p-3">
                    <p className="text-start mb-0">
                      This is the content of {item.name}.
                    </p>
                    <div>
                      <a
                        href={item.files.english}
                        download
                        className="btn btn-dark"
                        style={{ padding: "10px", marginLeft: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ marginRight: "5px" }}
                        />
                        English
                      </a>
                      <a
                        href={item.files.hindi}
                        download
                        className="btn btn-dark"
                        style={{ padding: "10px", marginLeft: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ marginRight: "5px" }}
                        />
                        Hindi
                      </a>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
