import React from "react";
import { useNavigate } from "react-router-dom";
import card1 from "../../assets/images/solution by application/cardimg11.jpg";
import card2 from "../../assets/images/solution by application/cardimg2.jpg";
import card3 from "../../assets/images/solution by application/cardiimg3.jpg";
import card4 from "../../assets/images/solution by application/cardimg4.jpg";
import card5 from "../../assets/images/solution by application/cardimg4.jpg";
import card6 from "../../assets/images/solution by application/cardiimg3.jpg";

export const KeyApplication = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="key-application-section ">
                <div className="container">
                    <h2 className="pb-4">Key Applications</h2>

                    <div className="row pt-4">
                        <div className="col-lg-3 col-md-6">
                            <div class="card mb-3">
                                <img src={card1} alt="" className="vector-img " style={{ height: "15rem" }} />
                                <div class="card-body">
                                    <h5 class="card-title  text-center">Technical Services & Supports</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div class="card mb-3">
                                <img src={card2} alt="" className="vector-img " style={{ height: "15rem" }} />
                                <div class="card-body">
                                    <h5 class="card-title text-center">Industrial Drives & Control</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div class="card mb-3">
                                <img src={card3} alt="" className="vector-img " style={{ height: "15rem" }} />
                                <div class="card-body">
                                    <h5 class="card-title text-center">Control Panels</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div class="card mb-3">
                                <img src={card5} alt="" className="vector-img " style={{ height: "15rem" }} />
                                <div class="card-body">
                                    <h5 class="card-title text-center">PLC/SCADA/HMI</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default KeyApplication;
