import React from "react";
import visonLeftImage from "../../assets/images/vision_home_left.jpg";
import img_ from "../../assets/images/img_.svg";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { useEffect, useRef } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

export const Vison = () => {
  // let mm = gsap.matchMedia();
  // const ref = useRef();
  //   useEffect(() => {
  //       const el = ref.current;
  //       gsap.fromTo(el, { scale: 0.9 }, {
  //           scale: 1, duration: 1, scrollTrigger: {
  //               trigger: el,
  //               scrub: true,
  //           }
  //       })
  //   }, [])

  const navigate = useNavigate();
  return (
    <>
      <div className="vison-section py-0 py-md-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5">
              <div className="vision-image">
                <img src={visonLeftImage} className="img-fluid mw-100" />
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <div className="vision-text-section py-4 py-lg-0">
                <h5 className="text-main">About Us</h5>
                <h2 className="fs-2 underline underline-heading texts text-justify">
                  Visves Automation
                </h2>
                <p className="text-base">
                  We design and engineer custom – automated control solutions
                  that reduce your costs and give your business the competitive
                  edge it needs to succeed.
                </p>
                <p className="text-base justify-content">
                  From concept to production, we are committed to developing,
                  building and maintaining quality automation systems that help
                  you reach your goals. Whether your aim is reduced downtime,
                  improved quality, increased efficiency and productivity,
                  enhanced safety or reduced energy costs, we are here to help
                  with professional service and expertise you can rely on.
                </p>
                <button
                  type="button"
                  className="brand-button"
                  onClick={() => navigate("/about")}
                >
                  Read More
                  <FontAwesomeIcon icon={faCircleArrowRight} className="ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
