import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 texts">
          Smart Integrated Pump & Valve Control Panel
        </h3>
        <p className="fs-5 texts mt-4">
          The Integrated Pump & Valve Control Panel ensures precise regulation
          of tank levels and optimizes the performance of valves and pumps in
          industrial water systems. Designed for advanced control, it supports
          seamless monitoring via cloud-based IoT systems and offers multiple
          power source configurations, including solar, making it ideal for
          critical water management operations.
        </p>
      </div>
    </React.Fragment>
  );
};
