import React, { useEffect, useState } from "react";
import logo1 from "../assets/images/img_group_1.svg";
import NavbarLink from "./NavbarLink"; // Assuming you have a separate component for navbar links
import "../styles/scss/navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  let [width, setWidth] = useState(window.innerWidth);
  const [searchBarValue, setSearchBarValue] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchBarValue(e.target.value);
  };
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const handleToggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };
  const updatewindowwidth = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updatewindowwidth);
    console.log("width", width);
    return () => {
      window.removeEventListener("resize", updatewindowwidth);
    };
  }, [window.innerWidth]);

  const TopNavbar = () => {
    return (
      <nav className="navbar navbar-expand-lg top-bar">
        <div className="collapse navbar-collapse " id="navbarNav">
          <ul className="navbar-nav ms-auto ">
            <li className="nav-item">
              <Link className="nav-link top-font1" to="/gallery">
                Gallery
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link top-font1" to="/carrer">
                Careers
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link top-font1" to="/blog">
                Blogs
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link className="nav-link top-font1" to="/news-article">
                News
              </Link>
            </li> */}

            {/* <li className="nav-item">
              <Link className="nav-link top-font1" to="/download">
                Downloads
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    );
  };
  const BottomNavbar = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const closeNavbar = () => setIsNavCollapsed(true);
    return (
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to="/">
          <img src={logo1} alt="logo" className="logo ms-3" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={closeNavbar}>
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Company
                <span className="arrow-dropdown"></span>
              </Link>
              <ul
                className="dropdown-menu"
                onClick={closeNavbar}
                aria-labelledby="navbarDropdown1"
              >
                <li>
                  <Link className="dropdown-item" to="/about">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/leadership">
                    Leadership
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/products" onClick={closeNavbar}>
                Products
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Solutions
                <span className="arrow-dropdown"></span>
              </Link>
              <ul
                className="dropdown-menu"
                onClick={closeNavbar}
                aria-labelledby="navbarDropdown2"
              >
                <li>
                  <Link className="dropdown-item" to="/solutionsbyindustry">
                    Solution by Industry
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/solutionbyApplication">
                    Solution by Application
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" onClick={closeNavbar}>
                Services
              </Link>
            </li>
            <li className="nav-item d-block d-lg-none">
              <Link className="nav-link" to="/gallery" onClick={closeNavbar}>
                Gallery
              </Link>
            </li>

            <li className="nav-item d-block d-lg-none">
              <Link className="nav-link" to="/carrer" onClick={closeNavbar}>
                Careers
              </Link>
            </li>

            <li className="nav-item d-block d-lg-none">
              <Link className="nav-link" to="/blog" onClick={closeNavbar}>
                Blogs
              </Link>
            </li>

            {/* <li className="nav-item d-block d-lg-none">
              <Link
                className="nav-link"
                to="/news-article"
                onClick={closeNavbar}
              >
                News
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us" onClick={closeNavbar}>
                Contact Us
              </Link>
            </li>

            {/* <div className={`search-container ${isSearchVisible ? 'search-visible' : ''}`} >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className={`ms-3`}

              style={{
                color: "#ffffff",
                top: "12px",
                color: "rgb(255, 255, 255)",
                position: "absolute",
                right: "21px"
              }}
              onClick={handleToggleSearch}
            />
            {isSearchVisible && (
              <form className="d-flex">
                <input
                  className="form-control me-2 navbar-input"
                  type="search"
                  placeholder="Search here..."
                  aria-label="Search"
                  onChange={handleSearchInputChange}
                />
              </form>
            )}
          </div> */}
            <>
              {/* {values.map((v, idx) => (
                <Button
                  key={idx}
                  className="brand-button"
                  onClick={() => handleShow(v)}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  {typeof v === "string" && `below ${v.split("-")[0]}`}
                </Button>
              ))} */}
              {/* <Modal
                className="search-modal"
                data-bs-theme="dark"
                show={show}
                fullscreen={fullscreen}
                onHide={() => setShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="text-white">Search Here</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Type here..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                      />
                      <Button variant="primary">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="me-2"
                        />
                        Search
                      </Button>{" "}
                    </InputGroup>
                  </Form>
                </Modal.Body>
              </Modal> */}
            </>
          </ul>
        </div>
      </nav>
    );
  };
  return (
    <div className="navbar-main sticky-top">
      {width > 992 ? <TopNavbar /> : <></>}
      <BottomNavbar />
    </div>
  );
};
