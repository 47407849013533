import React, { useState, useEffect } from "react";
import imagex from "../../assets/images/leadership/leadership-hero.png";
import { useNavigate } from "react-router-dom";

export const Hero = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Set loaded to true after a short delay to trigger the transition
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="position-relative py-5">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <img
              src={imagex}
              alt="Leardership Images"
              className="w-100 rounded-5"
            />
          </div>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0">
            <div className="hero-text-section position-relative z-index-1">
              <h3 className="text-about">
                Unlocking Innovation, Empowering Success
              </h3>
              <h1 className="hero-title">Meet Our Leadership Team</h1>
              <p className="hero-p">
                Welcome to Visves - where we cultivate innovation and guide you
                towards success. Our dynamic leadership team is dedicated to
                driving excellence and fostering growth in every endeavor. Join
                us on the journey to innovation-driven success!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
