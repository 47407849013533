import React, { useState, useRef, useEffect } from 'react';
import hero_video from "../../assets/video/homeVideo.mp4";

const VideoPlayer = () => {
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1); // Initial playback speed
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        setPlaying(true);
      }).catch(error => {
        // Auto-play was prevented
        // You may want to handle this according to your application's logic
        console.log("Autoplay prevented:", error);
      });
    }
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = speed;
    }
  }, [speed]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSpeed(prevSpeed => prevSpeed +0);
    }, 5000); // Adjust the interval duration as needed

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="video-player-container">  
 
    <div class="position-absolute top-0 start-0 w-100 h-100 gradient-bg"  ></div>
      <video
      ref={videoRef}
      src={hero_video}
      autoPlay
      muted  // Muted to ensure autoplay works across all browsers
      loop
      playsInline  // Needed for iOS devices
      className="custom-video" 
    />
    </div>
  
  );
};

export default VideoPlayer;
