import React from "react";
import imagex from "../../assets/images/aboutusImages/about_us_hero_right_image.svg";
import HeroImage from "../../assets/images/blog/blog-hero.jpg"
export const Hero = () => {
  return (
    <div className="common-hero-style hero-section position-relative py-5"
      style={{
        backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url(${HeroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
        <div
          className="black-layer position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        ></div>
        <div className="container">
          <div className="row d-flex">
            <div className="col-12 col-lg-8 py-5">
              <div className="hero-text-section position-relative z-index-1">
                <h3 className="text-about text-white">Dive into Our Latest Articles</h3>
                <h1 className="hero-title text-white">Blogs</h1>
                <p className="hero-p text-white">
                Welcome to our blog, where we share insights, ideas, and stories to inspire and inform. Dive into our collection of articles covering a wide range of topics, from industry trends to practical tips, all crafted to enhance your knowledge and spark new ideas.
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
