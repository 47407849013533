import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 texts">VAE RTU IoT Device</h3>
        <p className="fs-5 texts mt-4">
          The VAE RTU IoT is a powerful evaluation Device equipped with
          pre-installed firmware, designed to streamline the development of
          secure IoT solutions using Visves IoT Central. Its core functionality
          enables seamless wireless bi-directional communication using different
          industrial protocol making it ideal for industrial IoT applications
          requiring reliable and secure data exchange.
        </p>
      </div>
    </React.Fragment>
  );
};
