import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

export const PolicyContent = () => {
    return (
        <div className="policy-content-wrap py-5">
            <div className="container">
                <div class="policy-point">
                    <h5><FontAwesomeIcon icon={faAnglesRight} className="me-2" /> Information we collect from our clients</h5>
                    <p>We collect some of the personal details of clients like E-mail address, phone number, name IP address, browser and more just to get and store the data of our clients. This information is used to complete the product order or request. The client's personal information is not disclosed to any third parties.</p>
                    <p>We do not collect or store data for the visitors who only visit our website to check information.</p>
                </div>
                <div class="policy-point">
                    <h5><FontAwesomeIcon icon={faAnglesRight} className="me-2" /> Information we collect from our clients</h5>
                    <p>We collect some of the personal details of clients like E-mail address, phone number, name IP address, browser and more just to get and store the data of our clients. This information is used to complete the product order or request. The client's personal information is not disclosed to any third parties.</p>
                    <p>We do not collect or store data for the visitors who only visit our website to check information.</p>
                </div>
                <div class="policy-point">
                    <h5><FontAwesomeIcon icon={faAnglesRight} className="me-2" /> Information we collect from our clients</h5>
                    <p>We collect some of the personal details of clients like E-mail address, phone number, name IP address, browser and more just to get and store the data of our clients. This information is used to complete the product order or request. The client's personal information is not disclosed to any third parties.</p>
                    <p>We do not collect or store data for the visitors who only visit our website to check information.</p>
                </div>
                <div class="policy-point">
                    <h5><FontAwesomeIcon icon={faAnglesRight} className="me-2" /> Information we collect from our clients</h5>
                    <p>We collect some of the personal details of clients like E-mail address, phone number, name IP address, browser and more just to get and store the data of our clients. This information is used to complete the product order or request. The client's personal information is not disclosed to any third parties.</p>
                    <p>We do not collect or store data for the visitors who only visit our website to check information.</p>
                </div>
                <div class="policy-point">
                    <h5><FontAwesomeIcon icon={faAnglesRight} className="me-2" /> Information we collect from our clients</h5>
                    <p>We collect some of the personal details of clients like E-mail address, phone number, name IP address, browser and more just to get and store the data of our clients. This information is used to complete the product order or request. The client's personal information is not disclosed to any third parties.</p>
                    <p>We do not collect or store data for the visitors who only visit our website to check information.</p>
                </div>
            </div>
        </div>
    )
}