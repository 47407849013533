import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const BacktoproductPage = () => {
  return (
    <React.Fragment>
      <div className="container back-to-product-page mb-4 mt-4 pb-2 border-bottom">
        <Link to="/products" className="btn texts back-to-product">
          <FontAwesomeIcon icon={faChevronLeft} className="px-2 " />
          Back{" "}
        </Link>
      </div>
    </React.Fragment>
  );
};
