import React from "react";
import "../../styles/scss/leadership.scss";
import { Hero } from "./Hero";
import { Teams } from "./Teams";
export const Leadership = () => {
  return (
    <React.Fragment>
      <div className="leadership-page">
        <Hero />
        <Teams/>
      </div>
    </React.Fragment>
  );
};
