import React from "react";
import Service1 from "../../assets/images/serviceimg/technical-service.jpg";

// RenderList Component
const RenderList = ({ points, type }) => {
  // Determine the type of list (ordered or unordered)
  const ListTag = type === "ol" ? "ol" : "ul";

  return (
    <ListTag>
      {points.map((point, idx) => {
        if (typeof point === "string") {
          // Simple list item
          return <li key={idx}>{point}</li>;
        }

        // Nested list item with sublist handling
        return (
          <li key={idx}>
            {point.text}
            {point.sublist && (
              <RenderList
                points={point.sublist.points}
                type={point.sublist.type}
              />
            )}
          </li>
        );
      })}
    </ListTag>
  );
};

// BlogContent Component
export const BlogContent = ({ sections }) => {
  return (
    <div className="blog-content-wrap py-5">
      <div className="container">
        {sections?.map((section, sectionIndex) => (
          <div key={sectionIndex} className="blog-section mb-4">
            {/* Section Title */}
            <h2>{section.title}</h2>

            {/* Section Content */}
            {section.format === "paragraph" || section.format === "ul" ? (
              <p>{section.content}</p>
            ) : null}

            {/* Section Image */}
            {/* {section.image && (
              <img
                src={section.image}
                alt={`${section.title} Image`}
                className="img-fluid img-post mb-4"
              />
            )} */}

            {/* Render Lists */}
            {section.format === "ul" || section.format === "ol" ? (
              <RenderList points={section.points} type={section.format} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};
