import React from "react";

export const AboutContent = () => {
  return (
    <React.Fragment>
      <div className="about-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 pb-5 pb-lg-0">
              <p className="fs-2 underline underline-heading texts text-justify">
                Leading by Innovation
              </p>

              <p className="fs-5 text-body-secondary texts text-justify mb-0">
                At the heart of our identity lies an unwavering commitment to
                innovation. We don't merely follow industry trends; we set them.
                Through our relentless pursuit of cutting-edge solutions, we
                redefine what's possible in the realm of automation. By embracing
                the latest advancements in AI, machine learning, IoT, and
                robotics, we pioneer transformative technologies that
                revolutionize business operations. Our dedication to staying ahead
                of the curve is evident in every aspect of our work, from our
                rigorous research and development initiatives to our collaborative
                approach with clients. We believe that innovation isn't just about
                keeping up; it's about setting a new standard of excellence. This
                ethos drives us to continuously push boundaries, challenge
                conventions, and create solutions that not only meet but exceed
                expectations. At every turn, we lead by innovation, empowering
                businesses to embrace the future with confidence and drive
                unprecedented success in an ever-evolving world.
              </p>
            </div>
            <div className="col-lg-6 col-sm-12">
              <p className="fs-2 underline underline-heading texts">Who We Are</p>

              <p className="fs-5 text-body-secondary texts mb-0">
                Pioneers in the field of automation, we are a dynamic team
                dedicated to revolutionizing business operations through
                innovative solutions. With a passion for pushing boundaries, we
                harness cutting-edge technologies such as AI, machine learning,
                IoT, and robotics to create tailored automation systems. Our
                relentless pursuit of excellence drives us to stay at the
                forefront of industry trends, constantly refining our
                methodologies through extensive research and development.
                Embracing diversity and collaboration, we foster an environment
                where creativity thrives, allowing us to adapt swiftly to changing
                needs and challenges. Committed to sustainability, we prioritize
                solutions that not only enhance efficiency but also promote
                environmental responsibility. At our core, we are catalysts for
                change, empowering businesses to embrace the future with
                confidence and drive success through innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
