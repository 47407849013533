import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const textOptions = {
    Features: {
      list: [
        "Dual Power Supply Compatibility: Operates on both AC and DC, suitable for diverse applications.",
        "Solar Integration: Designed for seamless use in solar-powered systems.",
        "Automated ESR Control: Adjusts electricity supply levels based on GSR for optimal performance.",
        "8 Analog Sensor Support: Real-time monitoring with multiple sensor inputs for comprehensive data.",
        "User-Friendly HMI: Intuitive interface for easy operation and monitoring.",
        "IoT Cloud Monitoring: Remote access and control through cloud connectivity for enhanced data management.",
        "Flexible Operation Modes: Choose between automatic and manual modes based on operational needs.",
        "Actuator Panel Synchronization: Automatic control of valve operations, hooter alerts, and emergency stop functions.",
        "Robust Safety Features: Ensures safe and reliable operations in critical applications.",
        "Redundancy Feature: It has a Star Delta starter as well as VFD; users can choose as per their needs.",
      ],
    },
    Industries:
      "This innovative control panel is ideal for a variety of industries, including renewable energy, where it can optimize operations in solar power plants and energy management systems. In water treatment facilities, it supports monitoring and controlling processes for both water supply and waste management. The manufacturing sector benefits from its automation capabilities, enhancing process efficiency and machinery control. In agriculture, the panel enables smart farming solutions, particularly for irrigation and monitoring systems. It is also perfect for building automation, managing HVAC, lighting, and safety features, as well as the oil and gas industry, where it plays a crucial role in monitoring and controlling extraction and processing operations. This versatility makes our Integrated Control Panel a valuable asset across numerous applications.",
  };

  const [displayText, setDisplayText] = useState("");
  const [featureList, setFeatureList] = useState([]);

  const changeText = (text) => {
    if (text === "Features") {
      setDisplayText(textOptions[text].description);
      setFeatureList(textOptions[text].list);
    } else {
      setDisplayText(textOptions[text]);
      setFeatureList([]);
    }
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"].description);
    setFeatureList(textOptions["Features"].list);
  }, []);

  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText ===
                (option === "Features"
                  ? textOptions[option].description
                  : textOptions[option])
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }}
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
          {featureList.length > 0 && (
            <ul className="fs-5">
              {featureList.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
