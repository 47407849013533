import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import TechnicalServices from "../../assets/images/serviceimg/technical-service.jpg";
// import Service2 from "../../assets/images/serviceimg/control-panel.jpg";
// import Service3 from "../../assets/images/serviceimg/lab-equipment.jpg";
// import Service4 from "../../assets/images/serviceimg/industrial-drives.jpg";
// import Service5 from "../../assets/images/serviceimg/plc-scada.jpg";
import OperationalMaintenance from "../../assets/images/serviceimg/operation-maintenance.jpg";
import Consultancy from "../../assets/images/serviceimg/consultancy.jpg";
import IOTCloud from "../../assets/images/serviceimg/iot-cloud.jpg";
import Programming from "../../assets/images/serviceimg/programming.jpg";
import Card from "react-bootstrap/Card";

export const VServices = () => {
  return (
    <React.Fragment>
      <div className="services-section py-5">
        <div className="container">
          <div className="mb-4">
            <h3 className="fs-2 underline underline-heading texts text-justify">
              Services We Provide
            </h3>
            <p>
              Welcome to our Services page, where customer satisfaction and
              innovation are at the heart of everything we do. At{" "}
              <b>Visves Advance Engineering</b>, we deliver a diverse range of
              services designed to meet the evolving needs of our clients. From
              advanced panel manufacturing solutions to expert technical
              consultations, our experienced team is equipped to handle any
              challenge, ensuring efficiency, precision, and reliability in
              every project.
            </p>
          </div>
          <h4>Our Promise</h4>
          <div className="row mb-5">
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <Card>
                <Card.Body>
                  <Card.Title>24/7 Availability</Card.Title>
                  <Card.Text>
                    Our team is here to support you around the clock, ensuring
                    that your business never experiences downtime.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <Card>
                <Card.Body>
                  <Card.Title>Problem-Solving Experts</Card.Title>
                  <Card.Text>
                    No matter the complexity of the issue, we pride ourselves on
                    our ability to provide effective, timely solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <Card>
                <Card.Body>
                  <Card.Title>Client Satisfaction</Card.Title>
                  <Card.Text>
                    Your success is our goal, and we work diligently to ensure
                    that every project we undertake exceeds expectations.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Programming} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>Programming (PLC / HMI / Drive)</h3>
                  <p>
                    Our team specializes in the programming and configuration of
                    PLCs, HMIs, and Drives to automate and optimize your
                    industrial processes. We ensure seamless integration between
                    hardware and software, creating efficient systems that
                    maximize productivity and reduce downtime.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={IOTCloud} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>IoT Cloud Management</h3>
                  <p>
                    Unlock the full potential of your systems with our IoT cloud
                    management services. We provide robust solutions for
                    connecting and monitoring devices in real time, enabling you
                    to access data remotely, improve operational insights, and
                    enhance decision-making across your enterprise
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Consultancy} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>
                    Consultancy (Project Planning, Hardware Selection, System
                    Integration)
                  </h3>
                  <p>
                    Our consultancy services guide you through every stage of
                    your project. From strategic planning and hardware selection
                    to system integration, we provide expert advice that ensures
                    you make informed decisions, resulting in cost-effective and
                    high-performance solutions tailored to your specific
                    requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={TechnicalServices} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>
                    Technical Services and Support (Water Works Department,
                    Electrical Panels)
                  </h3>
                  <p>
                    We offer specialized technical services and support,
                    particularly for the Water Works Department and electrical
                    panel-related projects. Our expert technicians provide
                    comprehensive maintenance, troubleshooting, and repairs to
                    ensure smooth and uninterrupted operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={OperationalMaintenance} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>
                    Operation and Maintenance of Systems (AMC and Operation of
                    Installed Systems)
                  </h3>
                  <p>
                    Our Annual Maintenance Contracts (AMC) ensure the longevity
                    and performance of your systems. We also offer operation
                    services for installed systems, handling all aspects of
                    maintenance and operation to keep your infrastructure
                    running efficiently and effectively.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service1} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>TECHNICAL SERVICES & SUPPORTS</h3>
                  <p>
                    Control Panel Design/Fabrication: Industrial Electrical |
                    Electronic Enclosures | Customized. PLC / SCADA / HMI
                    Programming Services: Application and Program Development…
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service2} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>CONTROL PANELS</h3>
                  <p>
                    Distribution panels are required in each and every industry
                    for controlling action. We are manufacturer of Control
                    panels & APFC / PCC Electrical panels as per customer
                    requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service3} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>LAB EQUIPMENTS & CALIBRATION</h3>
                  <p>
                    Our company was engaged in the manufacturing of Lab
                    equipments and Interface Boards. With the efforts from our
                    Research and Development Team, the company has expanded its…
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service4} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>INDUSTRIAL DRIVES & CONTROL</h3>
                  <p>
                    With the increasing demand for energy efficiency, safety,
                    reliable connectivity and precise control, industrial drives
                    for factory automation systems are becoming more and more …
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service5} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>PLC/SCADA/HMI</h3>
                  <p>
                    Our core competency is providing solutions for the process
                    with good capability & reliability for the process to
                    control the critical parameters. Our team is capable for the
                    high …
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-box">
                <img src={Service6} alt="Service Image" />
                <div className="service-box-cont">
                  <h3>OPERATIONS AND MAINTENANCE</h3>
                  <p>
                    Our organization provide the best Operations and Maintenance
                    for Project. These service are being rendered on
                    instrumentation projects, automation projects and other
                    concerned projects…
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
