import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const textOptions = {
    Features: {
      list: [
        "Advanced Control: Manages up to 3 valves and 1 pump, with options for manual and automatic operation.",
        "VFD Technology: Uses Variable Frequency Drive (VFD) for energy-efficient pump control based on real-time water levels.",
        "Comprehensive Monitoring: Tracks ESR and GSR tank levels, inline pressure, flow rate, turbidity, and chlorine levels using up to 8 analog sensors.",
        "Dual Power Compatibility: Supports both AC and DC power, with automatic switching between sources for uninterrupted operation.",
        "IoT Integration: Cloud-based monitoring and control for real-time insights into system performance.",
      ],
    },
    Industries:
      "The Integrated Pump & Valve Control Panel is designed for use in a variety of industries that require precise water management and system optimization. It is ideal for water treatment and distribution, where it helps regulate tank levels, monitor water quality, and ensure efficient operation of pumps and valves. In industrial process automation, the control panel enhances operational efficiency by automating flow control and managing key water parameters. Municipalities can benefit from its use in municipal water supply systems, where it ensures reliable water distribution and prevents system failures. Additionally, it is well-suited for irrigation systems, optimizing water usage by regulating pumps and valves based on field requirements.",
  };

  const [displayText, setDisplayText] = useState("");
  const [featureList, setFeatureList] = useState([]);

  const changeText = (text) => {
    if (text === "Features") {
      setDisplayText(textOptions[text].description);
      setFeatureList(textOptions[text].list);
    } else {
      setDisplayText(textOptions[text]);
      setFeatureList([]);
    }
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"].description);
    setFeatureList(textOptions["Features"].list);
  }, []);

  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText ===
                (option === "Features"
                  ? textOptions[option].description
                  : textOptions[option])
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }}
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
          {featureList.length > 0 && (
            <ul className="fs-5">
              {featureList.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
