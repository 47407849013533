import React from "react";
import "../../styles/scss/blog.scss";
import { UnderProcess } from "./UnderProcess";

export const UnderMaintenance = () => {
  return (
    <React.Fragment>
      <div className="under-maintenance">
        <UnderProcess />
      </div>
    </React.Fragment>
  );
};
