import React, { useState, useEffect } from 'react';
import '../styles/scss/scroll_button.scss'; // You can create your own CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Add event listener to detect scrolling
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Determine when to display the button based on scroll position
  const handleScroll = () => {
    if (window.pageYOffset > 300) { // Adjust this value as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // You can change this to 'auto' for instant scrolling
    });
  };

  return (
    <div className={`scroll-to-top ${isVisible ? 'show' : 'hide'}`} onClick={scrollToTop}>
      <button><FontAwesomeIcon icon={faAngleUp} /></button>
    </div>
  );
};

export default ScrollToTopButton;
