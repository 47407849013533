import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 texts">
          Mini SCADA Panel for Pump Operation and ESR Tank Level Management
        </h3>
        <p className="fs-5 texts mt-4">
          Our Mini SCADA Panel is an advanced solution designed to efficiently
          operate pumps and maintain Elevated Storage Reservoir (ESR) tank
          levels. This compact yet powerful system leverages star-delta
          technology for pump operation, ensuring energy efficiency and reduced
          starting current. With the capability to support up to 8 analog
          sensors, our SCADA panel provides comprehensive monitoring and control
          of the process, enabling precise adjustments based on real-time data.
        </p>
      </div>
    </React.Fragment>
  );
};
