import React from "react";
import clientLogo from "../../assets/images/products/clientsLogo.png";
import client1 from "../../assets/images/client/aries.png";
import client2 from "../../assets/images/client/hetvi.JPG";
import client3 from "../../assets/images/client/jindal.jpg";
import client4 from "../../assets/images/client/scl.jpeg";
import client5 from "../../assets/images/client/shobikaa.jpeg";
import client6 from "../../assets/images/client/algocon.jpeg";
import client7 from "../../assets/images/client/chem.png";
import client8 from "../../assets/images/client/har-ghar-jalll.jpg";
import client9 from "../../assets/images/client/qpaix.JPG";
import client10 from "../../assets/images/client/rr-kabel.jpeg";
export const Clients = () => {
  return (
    <React.Fragment>
      <div className="clients-section pt-5">
        <div className="container">
          <div className="text-center mb-3">
            <h3 className="fs-2 underline underline-heading texts text-center">
              Our Clients
            </h3>
          </div>
          <div className="row align-items-center mx-auto justify-content-center">
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client1} alt="client 1" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client2} alt="client 2" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client3} alt="client 3" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client4} alt="client 4" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client5} alt="client 5" />
            </div>
          </div>
          <div className="row align-items-center mx-auto justify-content-center">
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client6} alt="client 6" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client7} alt="client 7" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client8} alt="client 8" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client9} alt="client 9" />
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
              <img src={client10} alt="client 10" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
