import React from "react";
import { Images } from "./Images";
import { ProductDescription } from "./ProductDescription";
export const ProductImageDesc = () => {
  return (
    <React.Fragment>
      <div className="container pt-4 pb-5 mb-5 border-bottom">
        <div className="row mt-2">
          <Images />
          <ProductDescription />
        </div>
      </div>
    </React.Fragment>
  );
};
