import React from "react";
import arrowRightIcon from "../../assets/images/img_.svg";
import { useNavigate } from "react-router-dom";
import imagex from "../../assets/images/ourProduct6.jpg";

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <div
      className="common-hero-style hero-section position-relative py-5"
      style={{
        backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url(${imagex})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="black-layer position-absolute top-0 start-0 end-0 bottom-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      ></div>
      <div className="container">
        <div className="row d-flex position-relative justify-content-center">
          <div className="col-12 col-lg-8 py-5">
            <div className="hero-text-section position-relative z-index-1">
              <h2 className="hero-title text-white">Our Products</h2>
              <p className="hero-p text-white">
                We design and engineer custom - automated control solutions that
                reduce your costs and give your business the competitive edge it
                needs to succeed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
