import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios"; // Import Axios
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export const Hero = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const emailUrl = process.env.REACT_APP_EMAIL_SERVICE;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        emailUrl, // Change URL if your server is running on a different port or domain
        {
          from: "support@qpaix.com",
          to: formData.email,
          subject: "Thanks for contacting us",
          body: `Dear ${formData.name},\n\n
         <p> Thank you for contacting us. We have received your message and will get back to you soon.\n\n</p>
         <p> Best Regards,\n</p>
         <p><b> Visves Advance Engineering.</b></p>`,
        }
      );
      const response1 = await axios.post(
        emailUrl, // Change URL if your server is running on a different port or domain
        {
          from: "support@qpaix.com",
          to: "info@visves.com",
          subject: "New contact from your website",
          body: `You have received a new message from your website:\n\n
         <p> Name: ${formData.name}\n</p>
         <p> Email: ${formData.email}\n</p>
        <p>  Message: ${formData.message}</p>`,
        }
      );
      if (response.data.status === true && response1.data.status === true) {
        toast.success("Thank you for contacting us.");
      }
      // Handle success, e.g., show a success message to the user
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email. Please try again later.");
      // Handle error, e.g., show an error message to the user
    }
  };

  return (
    <React.Fragment>
      <div className="hero-section py-5 border-bottom">
        <div className="container">
          <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">
            <div className="col-12 col-lg-6">
              <div className="hero-text-section">
                <div className="contact-mode-section">
                  <div className="contact-mobile">
                    <div className="card contact-card">
                      <div className="card-body contact-card-body">
                        <div className="contact-img">
                          <FontAwesomeIcon icon={faPhone} />
                        </div>
                        <div className="contact-text">
                          <div className="contact-title">Contact</div>
                          <p className="m-0 p-0">
                            <Link to="tel:9664980829">+91 9664980829</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-email">
                    <div className="card contact-card">
                      <div className="card-body contact-card-body">
                        <div className="contact-img">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                        <div className="contact-text">
                          <div className="contact-title">Email</div>
                          <p className="m-0 p-0">
                            <Link to="mailto:purchase@visves.com">
                              purchase@visves.com
                            </Link>
                          </p>
                          <p className="m-0 p-0">
                            <Link to="mailto:info@visves.com">
                              info@visves.com
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-location">
                    <div className="card contact-card">
                      <div className="card-body contact-card-body">
                        <div className="contact-img">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </div>
                        <div className="contact-text">
                          <div className="contact-title">Location</div>
                          <p className="m-0 p-0">
                            G-39, Safal Sumel Business Park-6, Nr. HanumanPura
                            BRTS Bus Stand, Dudheshawar, Ahmedabad, Gujarat -
                            380015.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="social-contact">
                  <ul>
                    <li className="mb-3">
                      <Link
                        to="https://www.facebook.com/Visvesadvanceengineering?mibextid=ZbWKwL"
                        className="brand-button"
                      >
                        <FontAwesomeIcon icon={faSquareFacebook} />
                        Facebook
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="https://in.linkedin.com/company/visvesadvanceengineering"
                        className="brand-button"
                      >
                        <FontAwesomeIcon icon={faLinkedin} />
                        Linkedin
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link
                        to="https://www.instagram.com/visvesadvance/"
                        className="brand-button"
                      >
                        <FontAwesomeIcon icon={faSquareInstagram} />
                        Instagram
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <h2 className="fs-2 underline underline-heading texts">
                Get in touch
              </h2>
              <p className="hero-p">
                We can help our team of experts is on hand to answer your
                questions.
              </p>
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div className="row form-row">
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        placeholder="Your name"
                        className="form-control form-control-lg custom-input"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your email"
                        className="form-control form-control-lg"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control form-control-lg"
                        id="exampleFormControlTextarea1"
                        rows="8"
                        placeholder="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn brand-button">
                        Submit Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
