import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const ApplyForm = ({ selectedOpening, onClose, _isOpen }) => {
  const [isOpen, setIsOpen] = useState(_isOpen);
  const [formData, setFormData] = useState({
    fullName: "",
    gender: "",
    dob: "",
    address: "",
    cityDistrict: "",
    state: "",
    pincode: "",
    phoneNumber: "",
    resume: null,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, resume: file });
  };

  const emailUrl = process.env.REACT_APP_EMAIL_SERVICE;

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Create a FormData object to handle multipart/form-data
    const formDataToSend = new FormData();
    formDataToSend.append("from", "support@qpaix.com");
    formDataToSend.append("to", "info@visves.com");
    formDataToSend.append("subject", "New Application Received");
    formDataToSend.append(
      "body",
      `
        <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
          <h2>New Application Received</h2>
          <p>Below are the details of the application:</p>
          <table style="border-collapse: collapse; width: 100%;">
            <tbody>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">Name</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.fullName}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">Gender</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.gender}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">Date of Birth</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.dob}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">Address</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.address}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">City/District</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.cityDistrict}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">State</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.state}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">Pincode</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.pincode}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">Phone Number</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${formData.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
      `
    );

    // Append the file as 'resume'
    formDataToSend.append("attachments", formData.resume);

    try {
      // Send POST request to the server
      const response = await axios.post(emailUrl, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data", // Explicitly set the content type
        },
      });

      console.log("Email sent successfully:", response.data);
      if (response.data.status === true) {
        handleClose();
        toast.success("Application submitted successfully");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <React.Fragment>
      <>
        {isOpen && (
          <div className="apply-form container">
            <div className="row mb-3 pb-3 border-bottom flex-md-row flex-column-reverse">
              <div className="col-lg-8 col-md-9">
                <h2 className="text-start underline underline-heading">
                  Apply for {selectedOpening ? selectedOpening : "Position"}
                </h2>
              </div>
              <div className="col-lg-4 col-md-3 text-right">
                <button
                  className="brand-button ms-auto me-0 d-block fs-3 py-0 px-3 text-end fw-bold"
                  onClick={handleClose}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
            <div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    {/* Full Name */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                      <label htmlFor="fullName">Full Name</label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {/* Date of Birth */}
                    <div className="form-floating mb-3">
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                      />
                      <label htmlFor="dob">Date of Birth</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* Address */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                      <label htmlFor="address">Address</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* City/District */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="cityDistrict"
                        name="cityDistrict"
                        placeholder="City/District"
                        value={formData.cityDistrict}
                        onChange={handleChange}
                      />
                      <label htmlFor="cityDistrict">City/District</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* State */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        name="state"
                        placeholder="State"
                        value={formData.state}
                        onChange={handleChange}
                      />
                      <label htmlFor="state">State</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* Pincode */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="pincode"
                        name="pincode"
                        placeholder="Pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                      />
                      <label htmlFor="pincode">Pincode</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* Phone Number */}
                    <div className="form-floating mb-3">
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                      <label htmlFor="phoneNumber">Phone Number</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* Gender */}
                    <div className="mb-3">
                      <label>Gender</label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="male"
                          value="male"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="male">
                          Male
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="female"
                          value="female"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="female">
                          Female
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="other"
                          value="other"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="other">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/* Resume Upload */}
                    <div className="mb-3">
                      <label htmlFor="resume">Upload Resume</label>
                      <br />
                      <input
                        type="file"
                        id="resume"
                        name="resume"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/* Submit Button */}
                    <button type="submit" className="btn brand-button">
                      Apply Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    </React.Fragment>
  );
};

export default ApplyForm;
