import React from "react";
import blogImg1 from "../../assets/images/products/DoorRTU.png";
import blogImg2 from "../../assets/images/products/ActuatorValve.png";
import blogImg3 from "../../assets/images/products/SCADAControlPanel.png";
import blogImg4 from "../../assets/images/products/SmartIntegratedPump.png";
import blogImg5 from "../../assets/images/products/advanced-plc-rtu.jpg";
import blogImg6 from "../../assets/images/products/ESP32IoTDevice.JPG";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Blog = () => {
  return (
    <React.Fragment>
      <div className="blog-section py-5 mb-5 border-bottom">
        <div className="container">
          <div className="row blog-row">
            <div className="col-12 col-lg-4 mb-4">
              <div className="card blog-card mb-4 mb-lg-0">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg1}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    3 Door Smart RTU Automated Control Panel
                  </h5>
                  <p className="card-text blog-card-text">
                    Introducing our advanced Control Panel, designed for
                    seamless operation in both AC and DC environments. This
                    versatile panel is optimized for ...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/door-smart-rtu">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card blog-card mb-4 mb-lg-0">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg2}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    Actuator Valve Control Panel
                  </h5>
                  <p className="card-text blog-card-text">
                    The Actuator Valve Control Panel by Visves Advance
                    Engineering is a cutting-edge solution designed for
                    efficient control of manual valves in various industrial ...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/actuator-valve-control-panel">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card blog-card">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg3}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    Mini SCADA Panel for Pump Operation and ESR Tank Level
                    Management
                  </h5>
                  <p className="card-text blog-card-text">
                    Our Mini SCADA Panel is an advanced solution designed to
                    efficiently operate pumps and maintain Elevated Storage
                    Reservoir (ESR) tank levels. This compact yet powerful
                    system ...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/mini-scada-control-panel">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card blog-card mb-4 mb-lg-0">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg4}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    Smart Integrated Pump and Valve Automated control Panel
                  </h5>
                  <p className="card-text blog-card-text">
                    The Integrated Pump & Valve Control Panel ensures precise
                    regulation of tank levels and optimizes the performance of
                    valves and pumps in industrial water systems ...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/smart-integrated-pump">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card blog-card mb-4 mb-lg-0">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg5}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    Advanced PLC RTU DI-DO-AI-AO Module
                  </h5>
                  <p className="card-text blog-card-text">
                    The VAEPRIO is a versatile PLC module combining digital and
                    analog input/output capabilities in a single compact design.
                    It ensures seamless integration with ...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/advanced-plc-rtu">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card blog-card">
                <img
                  className="card-img-top blog-card-img-top"
                  src={blogImg6}
                />
                <div className="card-body blog-card-body">
                  <h5 className="card-title-text blog-card-title">
                    VAE RTU IoT
                  </h5>
                  <p className="card-text blog-card-text">
                    The VAE RTU IoT is a powerful evaluation Device equipped
                    with pre-installed firmware, designed to streamline the
                    development of secure IoT solutions using ...
                  </p>
                  <div className="blog-card-footer">
                    <Link to="/products/vae-iot-rtu">
                      <button className="brand-button">
                        Learn More
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          className="ms-2 align-middle"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
