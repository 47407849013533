import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import textile from "../../assets/images/solutions/Textile.jpg";
import waterTreatment from "../../assets/images/solutions/Water Treatment.jpg";
import pharmaceutical from "../../assets/images/solutions/Pharmaceutical.jpg";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

export const Module = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="module-section my-5">
        <div className="container">
          <div className="row align-items-center flex-md-row flex-column-reverse">
            <div className="col-md-6 col-12 mt-md-0 mt-5">
              <h3 className="fs-2 underline underline-heading texts">Textile & Material Handling</h3>
              <ul>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Jet & yarn dyeing Machines</li>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Conveying & material handling</li>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Tower cranes & material hoists</li>
              </ul>
            </div>
            <div className="col-md-6 col-12">
              <div className="image-container">
                <img src={textile} alt="Textile" className="img-fluid shadow" />
              </div>
            </div>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-md-6 col-12">
              <div className="image-container">
                <img src={waterTreatment} alt="Water Treatment" className="img-fluid shadow" />
              </div>
            </div>
            <div className="col-md-6 col-12 mt-md-0 mt-5">
              <h3 className="fs-2 underline underline-heading texts">Water Treatment</h3>
              <ul>
                <li><FontAwesomeIcon icon={faAnglesRight} /> WTP, STP & ETP</li>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Municipal pumping & distribution</li>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Storm water & pumping station</li>
              </ul>
            </div>

          </div>
          <div className="row mt-5 align-items-center flex-md-row flex-column-reverse">
            <div className="col-md-6 col-12 mt-md-0 mt-5">
              <h3 className="fs-2 underline underline-heading texts">Pharmaceutical</h3>
              <ul>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Liquid manufacturing</li>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Solids Manufacturing</li>
                <li><FontAwesomeIcon icon={faAnglesRight} /> Sterilizers & dryers</li>
              </ul>
            </div>
            <div className="col-md-6 col-12">
              <div className="image-container">
                <img src={pharmaceutical} alt="Pharmaceutical" className="img-fluid shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
