import React from "react";
import { Hero } from "./Hero";
import Cards from "./Cards";
import { Module } from "./Module";
import "../../styles/scss/solutionsbyindustries.scss";
export const SolutionsByIndustry = () => {
  return (
    <React.Fragment>
      <div className="solutions-by-industry-page">
        <Hero />
        <Module />
      </div>
    </React.Fragment>
  );
};
