import React from "react";

export const Application = () => {
  return (
    <React.Fragment>
      <div className="about-section">
        <div className="container main-container d-flex flex-md-row flex-sm-column flex-column">
          <div className="col-md-6 col-sm-12 p-0 p-sm-5 ">
            <p className="fs-2 underline underline-heading texts text-justify">
            Leading by Innovation
            </p>

            <p className="fs-5 text-body-secondary texts text-justify">
            At Visves Advance Engineering, our dedication to innovation drives us to continuously push the boundaries of what's possible with IoT solutions. Through our expertise in smart home automation, industrial IoT, healthcare monitoring, environmental management, and beyond, we cater to a wide spectrum of industries. By prioritizing seamless integration and delivering unmatched performance, we equip businesses with the tools they need to not only adapt but excel in an ever-evolving connected world. Whether it's optimizing processes, enhancing efficiency, or improving overall quality, our cutting-edge solutions pave the way for transformative change.
            </p>
          </div>
          <div className="col-md-6 col-sm-12  p-0 p-sm-5">
            <p className="fs-2 underline underline-heading texts">Solutions Driving Success</p>

            <p className="fs-5 text-body-secondary texts">
            The IoT solutions offered by Visves Advance Engineering are tailored to meet the unique requirements of different industries. Whether it's streamlining manufacturing operations or improving healthcare services, their customizable applications yield measurable outcomes. By harnessing cutting-edge sensor technologies, data analytics, and machine learning, they enable businesses to achieve greater efficiencies, enhance safety measures, and elevate customer satisfaction. Visves Advance Engineering empowers organizations to propel their businesses forward with innovative IoT solutions.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
