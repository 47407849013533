import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 texts">3 Door Smart RTU Automated Control Panel</h3>
        <p className="fs-5 texts mt-4">
          Introducing our advanced Control Panel, designed for seamless
          operation in both AC and DC environments. This versatile panel is
          optimized for solar integration, making it ideal for sustainable
          energy applications. It features automatic control of ESR (Electricity
          Supply Regulation) levels based on GSR (Ground Supply Regulation)
          references, ensuring precise energy management.
        </p>
        <p className="fs-5 texts mt-4">
          The panel supports up to 8 analog sensors, allowing for real-time
          monitoring and data collection. Its user-friendly HMI (Human-Machine
          Interface) facilitates easy interaction and control, while IoT
          capabilities enable cloud monitoring for enhanced oversight and data
          analytics.
        </p>
        <p className="fs-5 texts mt-4">
          With both automatic and manual operation modes, our control panel
          offers flexibility in various settings. It synchronizes effortlessly
          with our actuator panel to automate valve operations, hooter alerts,
          and emergency stop functions, ensuring safety and efficiency.
        </p>
      </div>
    </React.Fragment>
  );
};
