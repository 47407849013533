import React from "react";

export const ProductDescription = () => {
  return (
    <React.Fragment>
      <div className="col-lg-6 mt-4 px-lg-5 justify-content-center mt-5 align-items-center">
        <h3 className="fs-2 texts">Advanced PLC RTU DI-DO-AI-AO Module</h3>
        <p className="fs-5 texts mt-4">
          The VAEPRIO is a versatile PLC module combining digital and analog
          input/output capabilities in a single compact design. It ensures
          seamless integration with PLCs and microcontrollers using RS485 or
          Ethernet communication protocols, offering flexible, efficient, and
          reliable solutions for industrial automation. Its user-friendly
          interface and compact DIN rail mountable design make it the perfect
          choice for optimizing control systems across industries.
        </p>
      </div>
    </React.Fragment>
  );
};
