import React from "react";
import { Hero } from "./Hero";
import "../../styles/scss/blog.scss";
import { Bloglisting } from "./Bloglisting";

export const Blog = () => {
  return (
    <React.Fragment>
      <div className="blog-page">
        <Hero />
        <Bloglisting />
      </div>
    </React.Fragment>
  );
};
