import { useNavigate } from "react-router-dom";

import React from "react";
import imagex from "../../assets/images/ourServices6.jpg";
export const Hero2 = () => {
  return (
    <React.Fragment>
      <div
        className="common-hero-style hero-section position-relative py-5"
        style={{
          backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url(${imagex})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="black-layer position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        ></div>
        <div className="container">
          <div className="row d-flex position-relative justify-content-center align-items-center">
            <div className="col-12 col-lg-8 py-5">
              <div className="hero-text-section position-relative z-index-1">
                <h2 className="hero-title text-white">Our Services</h2>
                <p className="hero-p text-white mt-md-0 mt-4 ">
                  We provided different kind of services to our clients and
                  users. Our team is easy to solve all types of problems.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="hero-section position-relative">
      <div className="black-layer">
        <div className="row">
          <div className="col-md-6">
            <div className="hero-text-section ms-5">
              <h2 className="hero-title ">Solutions By Application</h2>
              <p className="hero-p">
                Our innovative solution leverages cutting-edge technology to
                address real-world challenges efficiently.
              </p>

              <div></div>
            </div>
          </div>
          {/* You can uncomment this div and adjust the content accordingly if you want to include an image */}
          {/* <div className="col-md-6">
            <img src={heroImage} className="hero-image img-fluid" alt="Hero" />
          </div> */}
        </div>
      </div>
    </div>
  );
};
