import React from "react";
import imagex from "../../assets/images/aboutusImages/about_us_hero_right_image.svg";
import HeroImage from "../../assets/images/policy/policy-hero.jpg"
export const Hero = () => {
  return (
    <div className="common-hero-style hero-section position-relative py-5"
      style={{
        backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url(${HeroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
        <div
          className="black-layer position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        ></div>
        <div className="container">
          <div className="row d-flex">
            <div className="col-12 col-lg-8 py-5">
              <div className="hero-text-section position-relative z-index-1">
                <h3 className="text-about text-white">Our Commitment to Your Privacy</h3>
                <h1 className="hero-title text-white">Privacy Policy</h1>
                <p className="hero-p text-white">
                    At Visves, we are committed to protecting your privacy and ensuring the security of your personal information. Our Privacy Policy outlines how we collect, use, and safeguard the data you provide to us. Rest assured that your privacy is of the utmost importance to us, and we adhere to strict standards to keep your information safe. Explore our Privacy Policy to learn more about how we handle your data and maintain transparency in our practices.
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
