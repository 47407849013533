import React from "react";
import { useLocation } from "react-router-dom";
import { Hero } from "./Hero";
import "../../styles/scss/blog_details.scss";
import { BlogContent } from "./BlogContent";
import { blogs } from "./BlogData";
import { BackToBlogPage } from "./BackToBlogPage.js";

export const BlogDetails = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const blogId = parseInt(params.get("id"), 10); // Convert `id` to a number

  // Find the blog matching the `id`
  const blog = blogs.find((b) => b.id === blogId);

  return (
    <React.Fragment>
      <div className="blog-detail-page">
        <BackToBlogPage />
        <Hero
          title={blog?.title}
          writtenBy={blog?.writtenBy}
          timeStamp={blog?.timeStamp}
          image={blog?.image}
          logo={blog?.logo}
        />
        <BlogContent sections={blog?.sections} />
        {/* <CommentForm /> */}
      </div>
    </React.Fragment>
  );
};
