import React, { useState, useEffect } from "react";

export const FeaturesIndustries = () => {
  const textOptions = {
    Features: {
      list: [
        "Pump Control: Utilizes star-delta technology to optimize energy consumption and reduce wear on the pump.",
        "Multi-Sensor Support: Compatible with up to 8 analog sensors, allowing for extensive monitoring of various parameters such as tank levels and pressure.",
        "Real-Time Monitoring: Continuous tracking of sensor levels and pump status through our user-friendly Visves SCADA interface.",
        "Redundant Connection: Ensures operational reliability with a backup pump connection, minimizing downtime and enhancing system resilience.",
        "Safety Mechanisms: Integrated safety features monitor GSR and line pressure, providing alerts for any anomalies to prevent system failures.",
        "User-Friendly Interface: Intuitive controls and displays on the Visves SCADA platform for easy operation and data interpretation.",
        "Scalability: Easily expandable to accommodate future upgrades or additional sensors.",
      ],
    },
    Industries:
      "This Mini SCADA Panel is ideal for a range of industries, including water and wastewater management, where it optimizes municipal water supply and treatment processes. In industrial settings, it enhances fluid management for manufacturing plants. The agriculture sector benefits from precise irrigation control, while HVAC systems utilize it for effective fluid management in heating and cooling. Additionally, it meets hygiene standards in food and beverage production and supports safe handling of fluids in chemical processing. Overall, this product is designed to enhance operational efficiency, ensure safety, and provide real-time insights across various applications.",
  };

  const [displayText, setDisplayText] = useState("");
  const [featureList, setFeatureList] = useState([]);

  const changeText = (text) => {
    if (text === "Features") {
      setDisplayText(textOptions[text].description);
      setFeatureList(textOptions[text].list);
    } else {
      setDisplayText(textOptions[text]);
      setFeatureList([]);
    }
  };

  useEffect(() => {
    setDisplayText(textOptions["Features"].description);
    setFeatureList(textOptions["Features"].list);
  }, []);

  return (
    <React.Fragment>
      <div className="col-lg-6 align-items-start mb-4 mb-lg-0">
        <div className="pb-4 border-bottom text-center">
          {Object.keys(textOptions).map((option, index) => (
            <button
              key={index}
              className={`desc-buttons btn border-bottom-0 mb-3 texts ${
                displayText ===
                (option === "Features"
                  ? textOptions[option].description
                  : textOptions[option])
                  ? "btn-dark"
                  : "btn-tertiary"
              }`}
              onClick={() => changeText(option)}
              style={{ borderRadius: "0" }}
            >
              {option}
            </button>
          ))}
        </div>
        <div className="mt-3 w-100">
          <p className="fs-5 texts">{displayText}</p>
          {featureList.length > 0 && (
            <ul className="fs-5">
              {featureList.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
