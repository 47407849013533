import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
// import image1 from "../../assets/images/gallery/image1.jpg";
// import image2 from "../../assets/images/gallery/image2.jpg";
// import image3 from "../../assets/images/gallery/image3.jpg";
// import image4 from "../../assets/images/gallery/image4.jpg";
// import image5 from "../../assets/images/gallery/image5.jpg";
import Office1 from "../../assets/images/gallery/office1.JPG";
import Office2 from "../../assets/images/gallery/office2.JPG";
import Office3 from "../../assets/images/gallery/office3.png";
import Office4 from "../../assets/images/gallery/office4.JPG";
import Office5 from "../../assets/images/gallery/office5.JPG";
import Office6 from "../../assets/images/gallery/office6.JPG";
import Office7 from "../../assets/images/gallery/office7.jpg";
import Office8 from "../../assets/images/gallery/office8.jpg";
import Office9 from "../../assets/images/gallery/office9.jpg";
import Office10 from "../../assets/images/gallery/office10.jpg";
import Office11 from "../../assets/images/gallery/office11.jpg";
import Office12 from "../../assets/images/gallery/office12.jpg";

import Product1 from "../../assets/images/gallery/product1.JPG";
import Product2 from "../../assets/images/gallery/product2.JPG";
import Product3 from "../../assets/images/gallery/product3.JPG";
import Product4 from "../../assets/images/gallery/product4.JPG";
import Product5 from "../../assets/images/gallery/product5.JPG";
import Product6 from "../../assets/images/gallery/product6.JPG";

import Product7 from "../../assets/images/gallery/product7.png";
import Product8 from "../../assets/images/gallery/product8.png";
import Product9 from "../../assets/images/gallery/product9.png";
import Product10 from "../../assets/images/gallery/product10.png";
import Product11 from "../../assets/images/gallery/product11.png";
import Product12 from "../../assets/images/gallery/product12.png";

import Product13 from "../../assets/images/gallery/product13.JPG";
import Product14 from "../../assets/images/gallery/product14.png";
import Product15 from "../../assets/images/gallery/product15.JPG";
import Product16 from "../../assets/images/gallery/product16.JPG";
import Product17 from "../../assets/images/gallery/product17.JPG";

import Product18 from "../../assets/images/gallery/product18.png";
import Product19 from "../../assets/images/gallery/product19.png";
import Product20 from "../../assets/images/gallery/product20.png";
import Product21 from "../../assets/images/gallery/product21.jpg";
import Product22 from "../../assets/images/gallery/product22.png";

import Product23 from "../../assets/images/gallery/product23.png";
import Product24 from "../../assets/images/gallery/product24.jpg";
import Product25 from "../../assets/images/gallery/product25.JPG";
import Product26 from "../../assets/images/gallery/product26.JPG";
import Product27 from "../../assets/images/gallery/product27.JPG";
import Product28 from "../../assets/images/gallery/product28.JPG";

import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

// Import other images as needed

// { id: 3, src: image3 },
//     { id: 4, src: image4 },
//     { id: 6, src: image5 },
//     { id: 7, src: image1 },
//     { id: 8, src: image2 },
//     { id: 9, src: image3 },
//     { id: 10, src: image4 },
//     { id: 11 src: image5 },
export const GalleryImages = () => {
  const [show, setShow] = useState(null);
  const handleClose = () => setShow(null);
  const handleShow = (id) => setShow(id);

  const ImageAsModal = ({ src, show, handleClose }) => {
    console.log("clicked with", src, show);
    return (
      <Modal show={show} onHide={handleClose} className="gallery-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image src={src} fluid />
          {/* helllooo */}
        </Modal.Body>
      </Modal>
    );
  };
  const [ImgclassName, setImgClassName] = useState(
    "img-fluid w-100 gallery-image"
  );
  const itemsPerPage = 8;
  let v_photos = [
    { id: 1, src: Office1 },
    { id: 2, src: Office2 },
    { id: 3, src: Office3 },
    { id: 4, src: Office4 },
    { id: 5, src: Office5 },
    { id: 6, src: Office6 },
    { id: 7, src: Office7 },
    { id: 8, src: Office8 },
    { id: 9, src: Office9 },
    { id: 10, src: Office10 },
    { id: 11, src: Office11 },
  ];
  let products_photos = [
    { id: 13, src: Product1 },
    { id: 14, src: Product2 },
    { id: 15, src: Product3 },
    { id: 16, src: Product4 },
    { id: 17, src: Product5 },
    { id: 18, src: Product6 },
    { id: 19, src: Product7 },
    { id: 20, src: Product8 },
    { id: 21, src: Product9 },
    { id: 22, src: Product10 },
    { id: 23, src: Product11 },
    { id: 24, src: Product12 },
    { id: 25, src: Product13 },
    { id: 26, src: Product14 },
    { id: 27, src: Product15 },
    { id: 28, src: Product16 },
    { id: 29, src: Product17 },
    { id: 30, src: Product18 },
    { id: 31, src: Product19 },
    { id: 32, src: Product20 },
    { id: 33, src: Product21 },
    { id: 34, src: Product22 },
    { id: 35, src: Product23 },
    { id: 36, src: Product24 },
    { id: 37, src: Product25 },
    { id: 38, src: Product26 },
    { id: 39, src: Product27 },
    { id: 40, src: Product28 },
  ];
  const [activePage, setActivePage] = useState(0);
  const [activeButton, setActiveButton] = useState("all");
  const [dropdownText, setDropdownText] = useState("Select Category");
  const [allPhotos, setAllPhotos] = useState([...v_photos, ...products_photos]);

  const totalPages = Math.ceil(allPhotos.length / itemsPerPage);

  const handleAllPhotosClick = () => {
    setActiveButton("all");
    setActivePage(0);
    setDropdownText("All Photos");
    setAllPhotos([...v_photos, ...products_photos]);
  };

  const handlevisvesOfficeClick = () => {
    setActiveButton("visvesOffice");
    setActivePage(0);
    setDropdownText("Visves Office");
    setAllPhotos([...v_photos]);
  };

  const handleProductsClick = () => {
    setActiveButton("products");
    setActivePage(0);
    setDropdownText("Products");
    setAllPhotos([...products_photos]);
  };

  const handlePreviousClick = () => {
    if (activePage > 0) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextClick = () => {
    if (
      activePage < totalPages - 1 &&
      allPhotos.length > (activePage + 1) * itemsPerPage
    ) {
      setActivePage(activePage + 1);
    }
  };

  let slicedphotos = allPhotos?.slice(
    activePage * itemsPerPage,
    (activePage + 1) * itemsPerPage
  );

  return (
    <>
      <div className="gallery-section">
        <div className="container mt-5">
          <div className="row justify-content-center mb-5">
            <div className="col-md-12 d-none d-md-flex justify-content-center">
              <button
                className={`btn ${
                  activeButton === "all" ? "btn-primary" : "btn-secondary"
                }`}
                onClick={handleAllPhotosClick}
              >
                All Photos
              </button>
              <button
                className={`btn ${
                  activeButton === "visvesOffice"
                    ? "btn-primary"
                    : "btn-secondary"
                }`}
                onClick={handlevisvesOfficeClick}
              >
                Visves Office
              </button>
              <button
                className={`btn ${
                  activeButton === "products" ? "btn-primary" : "btn-secondary"
                }`}
                onClick={handleProductsClick}
              >
                Products
              </button>
            </div>
            <div className="col-lg-12 d-md-none d-flex justify-content-center">
              <div className="btn-group dropleft">
                <button
                  type="button"
                  className={`rounded-pill btn ${
                    activeButton === "all" ? "btn-primary" : "btn-secondary"
                  } dropdown-toggle`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {dropdownText}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAllPhotosClick();
                      }}
                    >
                      All photos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handlevisvesOfficeClick();
                      }}
                    >
                      Visves Office
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProductsClick();
                      }}
                    >
                      Products
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {slicedphotos
              // .slice(activePage * itemsPerPage, (activePage + 1) * itemsPerPage)
              .map((photo) => {
                return (
                  <div
                    key={photo.id}
                    className="col-lg-3 col-md-4 col-sm-6 mb-4"
                  >
                    <div className="gallery-image-box">
                      <img
                        src={photo.src}
                        className={ImgclassName}
                        alt={`Photo ${photo.id}`}
                        onClick={() => handleShow(photo?.id)}
                      />
                    </div>
                    {show === photo?.id && (
                      <ImageAsModal
                        src={photo.src}
                        show={true}
                        handleClose={handleClose}
                      />
                    )}
                    {/* <Image src={photo.src} alt={`Photo ${photo.id}`} className="img-fluid w-100 gallery-image" width="250" preview /> */}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="container">
          <hr className="mt-5"></hr>
          <div>
            <div className="d-flex justify-content-between">
              <button
                className="prev-next-color"
                onClick={handlePreviousClick}
                disabled={activePage === 0}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  className="pe-3"
                  icon={faCircleArrowLeft}
                  style={{
                    "--fa-primary-color": "#351ee0",
                    "--fa-secondary-color": "#f9f9f9",
                  }}
                />
                Previous
              </button>
              <button
                className="prev-next-color"
                onClick={handleNextClick}
                disabled={
                  activePage === totalPages - 1 ||
                  allPhotos.length <= (activePage + 1) * itemsPerPage
                }
                style={{ cursor: "pointer" }}
              >
                Next
                <FontAwesomeIcon className="ps-3" icon={faCircleArrowRight} />
              </button>
            </div>
            <hr className="mb-5"></hr>
          </div>
        </div>
      </div>
    </>
  );
};
