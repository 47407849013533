import React from "react";
import { Hero } from "./Hero";
import { WhyVisves } from "./WhyVisves";
import { Offer } from "./Offer";
import ApplyForm from "./ApplyForm"; // Import your ApplyForm component
import "../../styles/scss/carrer.scss";

export const Carrer = () => {
  return (
    <React.Fragment>
      <div className="carrer">
        <Hero />
        <WhyVisves />
        <Offer />
        {/* <ApplyForm /> Include your ApplyForm component */}
      </div>
    </React.Fragment>
  );
};
